<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin khách hàng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <b-form-group class="col-3">
                  <template>
                    <label>Họ:</label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="lastName"
                    required
                    placeholder="Nhập họ"
                    class="mb-3"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-3">
                  <template>
                    <label>Tên:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên"
                    aria-describedby="input-live-help input-1-live-feedback"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lỏng nhập tên khách hàng</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group class="col-3">
                  <template>
                    <label>Điện thoại:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="$v.form.phone.$model"
                    :state="validateState('phone')"
                    required
                    placeholder="Nhập điện thoại"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Số điện thoại không thể để trống</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Email:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-input
                    size="sm"
                    id="input-1"
                    type="text"
                    v-model="email"
                    required
                    placeholder="Nhập email"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  class="mb-3 col-md-3"
                  id="input-group-1"
                  label="Ngày sinh:"
                  label-for="input-1"
                >
                  <div style="display: flex">
                    <date-picker
                      placeholder="Chọn ngày sinh"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="birthday"
                    ></date-picker>
                  </div>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Giới tính:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedGender"
                    :options="listGender"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >-- Giới tính --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Loại:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedType"
                    :options="listType"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >-- Loại --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Nhân viên phụ trách:"
                  label-for="input-1"
                  class="col-3"
                >
                  <vue-autosuggest
                    v-model="searchEmployee"
                    :suggestions="filteredOptionEmployee"
                    @selected="onSelectedEmployee"
                    :limit="10"
                    @input="onInputChangeEmployee"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Chọn nhân viên',
                    }"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  id="input-group-1"
                  label="Thành phố:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedCity"
                    v-on:change="getDistrict"
                    :options="listCity"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >-- Thành phố --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Quận:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedDistrict"
                    :options="listDistrict"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    v-on:change="getWard"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >-- Quận --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Phường:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectWard"
                    :options="listWard"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >-- Phường --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Địa chỉ:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-input
                    size="sm"
                    id="input-1"
                    type="text"
                    v-model="address"
                    required
                    placeholder="Nhập địa chỉ"
                    aria-describedby="input-live-help input-1-live-feedback"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  id="input-group-1"
                  label="Ghi chú:"
                  label-for="input-1"
                  class="col-6"
                >
                  <b-form-textarea
                    size="sm"
                    id="textarea1"
                    v-model="note"
                    :placeholder="'thêm ghi chú'"
                    :rows="6"
                    :max-rows="8"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createCustomer"
              >Lưu</b-button
            >
            <router-link :to="'/customers'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget .dropdown-menu {
  width: 21em;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { VueAutosuggest } from 'vue-autosuggest';
import { xoa_dau } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      valid: true,
      form: {
        name: '',
        phone: '',
      },
      isNew: true,
      radios: 'male',
      selectedType: null,
      listType: [
        { id: 1, name: 'Khách lẻ' },
        { id: 2, name: 'Khách buôn' },
        { id: 3, name: 'Đại lý' },
      ],
      note: '',
      birthday: '',
      address: '',
      selectedCity: null,
      listCity: [],
      selectedDistrict: null,
      listDistrict: [],
      selectWard: null,
      listWard: [],
      selectedGender: null,
      listGender: [
        { id: 0, name: 'Nữ' },
        { id: 1, name: 'Nam' },
      ],
      selectedEmployee: null,
      listEmployee: [],
      lastName: '',
      filteredOptionEmployee: [],
      optionEmployee: [
        {
          data: [],
        },
      ],
      searchEmployee: '',
      email: '',
    };
  },
  validations: {
    form: {
      name: { required },
      phone: { required },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    VueAutosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khách hàng', route: '/customers' },
      { title: 'Danh sách khách hàng', route: '/customers' },
      { title: 'Thêm khách hàng' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.fetchCity();
  },
  methods: {
    format(value) {
      this.birthday = value;
      return value;
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    createCustomer: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let gt = false;
      if (this.selectedGender == 1) {
        gt = true;
      }
      let bd = this.birthday
        ? moment(this.birthday, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (this.searchEmployee.trim() === '') {
        this.searchEmployee = null;
      }
      let data = {
        data: {
          fullName: this.$v.form.name.$model,
          phoneNo: this.$v.form.phone.$model,
          gender: gt,
          email: this.email ? this.email.trim() : '',
          dateOfBirth: bd,
          city: this.selectedCity,
          district: this.selectedDistrict,
          ward: this.selectWard,
          address: this.address,
          type: this.selectedType,
          inChargeEmployeeId: this.selectedEmployee,
          note: this.note,
          deleted: false,
          totalBuyedAmount: 0,
          totalBuyedNo: 0,
          lastedBuyedAt: '',
          lastName: this.lastName ? this.lastName.trim() : '',
        },
      };
      ApiService.setHeader();
      ApiService.post('customer', data)
        .then(() => {
          this.makeToastSuccess('Thêm khách hàng thành công');
          this.$router.push({
            path: '/customers',
          });
        })
        .catch(() => {
          this.makeToastFaile('Thêm khách hàng bị lỗi!!!!');
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function () {
      this.optionEmployee[0].data = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee[0].data.push(store);
        });
        this.filteredOptionEmployee = [{ data: this.optionEmployee[0].data }];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>
